import Cookies from 'js-cookie'

export default class Cookiebar {
  static name = "cookiebar"

  cookieName = "cookiebar_policy"
  showMessageClass = "cookiebar--message-visible"
  showPreferencesClass = "cookiebar--preferences-visible"

  constructor(el, eventBus) {
    this.el = el

    // Preferences
    this.checkboxes = el.querySelectorAll("input[type='checkbox']")

    // All action buttons on cookiebar
    this.preferencesLink = el.querySelector("[data-do='preferences']")
    this.acceptButton = el.querySelector("[data-do='accept']")
    this.rejectButton = el.querySelector("[data-do='reject']")
    this.acceptAllButton = el.querySelector("[data-do='accept-all']")
    this.saveButton = el.querySelector("[data-do='save']")

    // Look for cookiebar buttons on the page
    const changeButton = document.querySelector("[data-do='cookiebar-change']")
    if (changeButton) {
      changeButton.addEventListener("click", () => {
        this.showPreferences()
      })
    }
    const resetButton = document.querySelector("[data-do='cookiebar-reset']")
    if (resetButton) {
      resetButton.addEventListener("click", () => {
        this.clearValue()
        this.showMessage()
      })
    }

    this.preferencesLink.addEventListener("click", () => {
      this.showPreferences()
    })

    this.acceptButton.addEventListener("click", () => {
      this.hide()
      this.selectAllVariants()
      this.saveValue()
    })

    this.rejectButton.addEventListener("click", () => {
      this.hide()
      this.clearAllVariants()
      this.saveValue()
    })

    this.acceptAllButton.addEventListener("click", () => {
      this.hide()
      this.selectAllVariants()
      this.saveValue()
    })

    this.saveButton.addEventListener("click", () => {
      this.hide()
      this.saveValue()
    })

    this.init()
  }

  init = () => {
    const currentValue = this.getCurrentValue()
    if (currentValue === undefined || currentValue === "") {
      this.showMessage()
    } else {
      const values = currentValue.split(",")
      for (let id of values) {
        const checkbox = this.el.querySelector(`#${id}`)
        if (checkbox) {
          checkbox.checked = "checked"
        }
      }
    }
  }

  showMessage = () => {
    this.el.classList.remove(this.showPreferencesClass)
    this.el.classList.add(this.showMessageClass)
  }

  showPreferences = () => {
    this.el.classList.remove(this.showMessageClass)
    this.el.classList.add(this.showPreferencesClass)
  }

  hide = () => {
    this.el.classList.remove(this.showPreferencesClass)
    this.el.classList.remove(this.showMessageClass)
  }

  getCurrentValue = () => {
    return Cookies.get(this.cookieName)
  }

  saveValue = () => {
    const newValue = this.calculateNewValue()

    const options = {
      expires: 365,
      secure: location.protocol === 'https:',
      sameSite: 'lax'
    }

    Cookies.set(this.cookieName, newValue, options)
  }

  clearValue = () => {
    Cookies.remove(this.cookieName)
  }

  calculateNewValue = () => {
    let values = []
    for (let checkbox of this.checkboxes) {
      if (checkbox.checked || checkbox.required) {
        values.push(checkbox.id)
      }
    }
    return values.join()
  }

  selectAllVariants = () => {
    for (let checkbox of this.checkboxes) {
      checkbox.checked = "checked"
    }
  }

  clearAllVariants = () => {
    for (let checkbox of this.checkboxes) {
      if (!checkbox.required) {
        checkbox.checked = ""
      }
    }
  }


}
